import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  collection,
  collectionData,
  query,
  where
} from '@angular/fire/firestore';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DBCollection, FAQ } from '@buildshare/common';
import {
  IonAccordion,
  IonAccordionGroup,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonRouterLink,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { caretDownCircleOutline, chevronForward } from 'ionicons/icons';
import { Observable, catchError, retry } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { AnalyticsService } from './../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    IonRouterLink,
    AsyncPipe,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
    IonSkeletonText,
    IonFooter,
    NgOptimizedImage
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HowItWorksComponent implements OnInit {
  faqs$: Observable<FAQ[]>;
  beforeInvestment: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firestore: Firestore,
    private analytics: AnalyticsService,
    private meta: Meta,
    public storage: StorageService
  ) {
    addIcons({ chevronForward, caretDownCircleOutline });
  }

  ngOnInit() {
    this.meta.updateTag({
      name: 'description',
      content: 'Learn how investing in a Buildshare project works.'
    });
    this.beforeInvestment = this.route.snapshot.parent.url.length > 0;
    this.faqs$ = collectionData<FAQ>(
      query(
        collection(
          this.firestore,
          DBCollection.FAQs
        ) as CollectionReference<FAQ>,
        where('featured', '==', true)
      ),
      { idField: 'id' }
    ).pipe(
      catchError((error) => {
        this.analytics.handleExpectedErrors(
          'Failed to fetch FAQs',
          error,
          {},
          "Couldn't fetch FAQs. Check your connection."
        );
        throw error;
      }),
      retry({ count: 5, delay: 5000 })
    );
  }

  accordionGroupChange(ev, faqs: FAQ[]) {
    const id = ev.detail.value;
    if (faqs) {
      this.analytics.event('select_item', {
        item_list_id: 'faqs',
        items: [{ id: id, name: faqs.find((f) => f.id === id)?.q }]
      });
    }
  }
}
